<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
        class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">Chi tiết chấm công
          </h2>
          <validation-observer ref="wifiDTO">
            <b-row class="col-md-12 col-12 mt-5">
              <b-col class="col-md-6 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Mã nhân viên"
                  >
                    <label
                      class="label-add-work-shift"
                      for="address"
                    >Mã nhân viên</label>
                    <input
                      id="address"
                      v-model="employeeCode"
                      :disabled="true"
                      type="text"
                      class="form-control"
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-6 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Họ tên"
                  >
                    <label
                      class="label-add-work-shift"
                      for="address"
                    >
                      Họ tên </label>
                    <input
                      id="wifiBSSID"
                      v-model="fullName"
                      rules="required"
                      :disabled="true"
                      type="text"
                      class="form-control"
                    >
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12 mt-1">
              <b-col class="col-md-4 col-12">
                <span>Đi muộn: <a
                  style="color: red; font-size: 25px;margin-left: 10px; margin-right: 5px;"
                >{{ lamMuon.length }}</a>Ca.</span>
              </b-col>
              <b-col class="col-md-4 col-12">
                <span>Về sớm: <a
                  style="color: red; font-size: 25px;margin-left: 10px; margin-right: 5px;"
                >{{ veSom.length }}</a>Ca.</span>
              </b-col>
              <b-col class="col-md-4 col-12">
                <span>Nghỉ làm: <a
                  style="color: red; font-size: 25px;margin-left: 10px; margin-right: 5px;"
                >{{ nghiLam.length }}</a>Ca.</span>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12 mt-1">
              <!-- Calendar -->
              <div class="col position-relative">
                <div class="card shadow-none border-0 mb-0 rounded-0">
                  <div class="card-body pb-0">
                    <div
                      class=" flex sm:justify-end justify-center order-last"
                    >
                      <div class="flex items-center">
                        <feather-icon
                          :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                          svg-classes="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full feateri"
                          @click="updateMonth(-1)"
                        />

                        <span
                          class="mx-3 mt-1 text-xl font-medium whitespace-no-wrap"
                          style="font-size: 18px"
                        >{{
                          showDateString
                        }}</span>

                        <feather-icon
                          :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                          svg-classes="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full feateri"
                          @click="updateMonth(1)"
                        />
                      </div>
                    </div>
                    <full-calendar
                      ref="fullCalendar"
                      :options="calendarOptions"
                      class="full-calendar"
                    />
                  </div>
                </div>
              </div>
            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  :to="{ name: 'time-keeping'}"
                  variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import moment from 'moment'
import CurrencyInput from '@core/components/currency-input/CurrencyInput.vue'
import DateTimeInput from '@core/components/date-time-input/DateTimeInput.vue'
import calendarStoreModule from './calendarStoreModule'
import useCalendar from './useCalendar'

export default {
  components: {
    DateTimeInput,
    CurrencyInput,
    BCard,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    FullCalendar,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      showDate: new Date(),
      showDateString: moment(this.showDate).format('DD/MM/YYYY'),
      lamMuon: [],
      veSom: [],
      nghiLam: [],
      required,
      dir: 'rtl',
      isUpdate: false,
      wifiDTO: {},
      fullName: null,
      employeeCode: null,
      idWDetail: null,
      calendarView: 'month',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: '',
          end: '',
        },
        events: [
          // { title: 'checkin: 09:06', date: '2022-03-28' },
        ],
      },
    }
  },
  computed: {
  },
  created() {
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idWDetail = parameters.id
      this.getInfoDetails()
    }
  },
  methods: {
    updateMonth(val) {
      const calendarApi = this.$refs.fullCalendar.getApi()
      if (val === 1) {
        calendarApi.next(val)
      }
      if (val === -1) {
        calendarApi.prev(val)
      }
      this.showDate = calendarApi.currentData.currentDate
      this.showDateString = moment(this.showDate).format('DD/MM/YYYY')
      const start = new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1)
      const end = new Date(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).setDate(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).getDate() + 31))

      const searchInfo = {
        organizationBranchId: null,
        employeeId: [this.$route.query.id],
        start: start.toISOString(),
        end: end.toISOString(),
      }
      this.getInfoDetails(searchInfo)
    },
    getInfoDetails(obj) {
      const { id } = this.$route.query
      if (!id) {
        return
      }
      const payload = {
        employeeId: [id],
        start: new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1),
        organizationBranchId: null,
        end: new Date(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).setDate(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).getDate() + 31)),
      }
      this.$crm.post('time-keeping/find', obj || payload).then(response => {
        if (response.data) {
          this.fullName = response.data[0].fullName
          this.employeeCode = response.data[0].employeeCode
          this.lamMuon = response.data.filter(v => (v.startHour + v.bufferTime) < v.checkinToTime)
          this.veSom = response.data.filter(v => v.checkinToTime > 0 && v.endHour > v.checkoutToTime)
          this.nghiLam = response.data.filter(v => !v.checkinToTime)
          this.calendarOptions.events = []
          response.data.forEach(v => {
            if (v.date && v.endDate) {
              this.calendarOptions.events.push({
                title: v.checkinTime ? `Checkin: ${v.checkinTime}` : 'Checkin: ',
                date: moment(v.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              })
              this.calendarOptions.events.push({
                title: v.checkoutTime ? `Checkout: ${v.checkoutTime}` : 'Checkout: ',
                date: moment(v.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manager-workShift';
@import '../../@core/scss/vue/apps/calendar.scss';
.feateri {
  width: 20px!important;
  height: 20px!important;
}
</style>
